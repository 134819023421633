<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div
          class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar"
        >
          <Sidebar />
        </div>
        <div class="column">
          <div>
            <div class="is-flex block">
              <div>
                <h1 class="title">{{ exam.title }} Results</h1>
                <h2 class="subtitle">
                  Check submitted results
                </h2>
              </div>
              <input
                v-model="memberSearch"
                class="input"
                type="search"
                placeholder="Search results"
                style="width: 25%"
              />
            </div>
            <div class="card block">
              <div class="card-content">
                <table class="table is-fullwidth is-hoverable is-narrow">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Email</th>
                      <th>Discord</th>
                      <th>Telegram</th>
                      <th>Result</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>#</th>
                      <th>Email</th>
                      <th>Discord</th>
                      <th>Telegram</th>
                      <th>Result</th>
                    </tr>
                  </tfoot>
                  <tbody>
                    <tr v-for="(user, i) in filteredMembers" :key="user.id">
                      <th>{{ i + 1 }}</th>
                      <td>{{ user.email }}</td>
                      <td>{{ discordText(user) }}</td>
                      <td>{{ user.telegram_id || "" }}</td>
                      <!--                    <td>{{ getResult(user) }}</td>-->
                      <td>
                        <div class="field is-grouped">
                          <button
                            @click="showResult(user)"
                            class="button is-small is-primary"
                          >
                            Show
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="{ 'is-active': editUserModal }" class="modal">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <div>
            <p class="modal-card-title">{{ exam.title }} Result</p>
            <p class="is-size-6">Email: {{ selectedUser.email }}</p>
            <p class="is-size-6">Discord: {{ discordText(selectedUser) }}</p>
            <p class="has-text-weight-light is-size-7">{{ selectedUser.id }}</p>
          </div>
        </header>
        <section class="modal-card-body">
          <div class="content">
            <label class="label">TradingView Link</label>
            <div class="field has-addons">
              <div class="control" style="flex: 1">
                <input
                  v-model.trim="selectedUserResults.tradingViewLink"
                  class="input"
                  type="text"
                />
              </div>
              <p class="control">
                <a
                  :href="selectedUserResults.tradingViewLink"
                  target="_blank"
                  class="button"
                >
                  Open
                </a>
              </p>
            </div>
            <div v-for="question in questions" :key="question.id" class="field">
              <label class="label">{{ question.question }}</label>
              <div class="control">
                <template v-if="question.type === 'free'">
                  <textarea
                    :value="selectedUserResults[question.id]"
                    class="textarea"
                    readonly
                  ></textarea>
                </template>
                <template v-if="question.type === 'choice'">
                  <input
                    :value="getAnswer(selectedUserResults[question.id])"
                    type="text"
                    class="input"
                    readonly
                  />
                </template>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="editUserModal = false">
            Close
          </button>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import { auth, db } from "@/firebase";

export default {
  name: "EducationExamsExamResults",
  components: {
    Sidebar,
    Mobilemenu,
  },
  data() {
    return {
      exam: {},
      results: [],
      questions: [],
      answers: [],
      resultsLoaded: false,
      memberSearch: null,
      date: new Date(),
      users: [],
      editUserModal: false,
      selectedUser: {
        id: null,
        balance: 0,
        subscription_end_date: new Date().toISOString(),
        telegram_id: null,
        notes: "",
      },
      selectedUserResults: {},
      userSaveLoading: false,
    };
  },
  watch: {
    id: {
      // call it upon creation too
      immediate: true,
      handler(id) {
        this.$bind(
          "results",
          db
            .collection("exams")
            .doc(this.$route.params.exam)
            .collection("results")
        )
          .then((results) => {
            results.forEach((result) => {
              console.log(result);
              db.collection("users")
                .doc(result.id)
                .get()
                .then((user) => {
                  this.users.push(user.data());
                });
            });
          })
          .then((results) => {
            this.$bind(
              "exam",
              db.collection("exams").doc(this.$route.params.exam)
            );
          })
          .then((results) => {
            this.$bind(
              "answers",
              db
                .collection("exams")
                .doc(this.$route.params.exam)
                .collection("answers")
            );
          })
          .then((results) => {
            this.$bind(
              "questions",
              db
                .collection("exams")
                .doc(this.$route.params.exam)
                .collection("questions")
            );
          })
          .finally(() => {
            this.resultsLoaded = true;
          });
      },
    },
  },
  filters: {
    date(val) {
      if (val) {
        return val.toDate().toLocaleString();
      } else {
        return val;
      }
    },
  },
  computed: {
    orderedMembers() {
      const active_subs = this.users
        .filter((user) => user.status === "active")
        .sort((a, b) => a.subscription_end_date - b.subscription_end_date);
      const expired_subs = this.users.filter(
        (user) => user.status === "expired"
      );
      const inactive_subs = this.users.filter(
        (user) => user.status === "registered"
      );
      return [...active_subs, ...expired_subs, ...inactive_subs];
      // return this.users.sort((a, b) => a.subscription_end_date - b.subscription_end_date);
    },
    filteredMembers() {
      if (!this.memberSearch) {
        return this.orderedMembers;
      } else {
        return this.orderedMembers.filter((member) => {
          let search_lowercase = this.memberSearch.toLowerCase();
          if (
            member.status &&
            member.status.toLowerCase().includes(search_lowercase)
          ) {
            return true;
          } else if (
            member.email &&
            member.email.toLowerCase().includes(search_lowercase)
          ) {
            return true;
          } else if (
            member.notes &&
            member.notes.toLowerCase().includes(search_lowercase)
          ) {
            return true;
          } else if (
            member.discord_username &&
            member.discord_username.toLowerCase().includes(search_lowercase)
          ) {
            return true;
          } else
            return !!(
              member.notes &&
              member.notes.toLowerCase().includes(search_lowercase)
            );
        });
      }
    },
  },
  methods: {
    getResult(user) {
      return this.results.find((result) => result.id === user.id)
        .tradingViewLink;
    },
    getAnswer(answer) {
      return this.answers.find((x) => x.id === answer).answer;
    },
    showResult(user) {
      this.editUserModal = true;
      this.selectedUser = user;
      this.selectedUserResults = this.results.find(
        (result) => result.id === user.id
      );
    },
    membershipText(user) {
      if (user.subscription_end_date?.toDate() > new Date()) {
        return user.subscription_end_date?.toDate().toLocaleString();
      } else if (user.subscription_end_date?.toDate() < new Date()) {
        return "expired";
      } else {
        return "inactive";
      }
    },
    discordText(user) {
      if (user.discord_id) {
        return `${user.discord_username}#${user.discord_discriminator}`;
      } else {
        return "not connected";
      }
    },
    editUser(user) {
      this.selectedUser = { ...user };
      if (user.subscription_end_date) {
        this.selectedUser.subscription_end_date = new Date(
          user.subscription_end_date.seconds * 1000
        )
          .toISOString()
          .slice(0, 16);
      } else {
        this.selectedUser.subscription_end_date = new Date()
          .toISOString()
          .slice(0, 16);
      }
      this.editUserModal = true;
    },
    async saveUser() {
      this.userSaveLoading = true;
      await this.$firestoreRefs.users.doc(this.selectedUser.id).update({
        balance: this.selectedUser.balance,
        subscription_end_date: new Date(
          this.selectedUser.subscription_end_date
        ),
        telegram_id: this.selectedUser.telegram_id || "",
        notes: this.selectedUser.notes || "",
      });
      this.userSaveLoading = false;
      this.editUserModal = false;
    },
    async extendUserMemberships() {
      console.log("disabled");
      // this.activeMembers.forEach(user => {
      //   const d = new Date(user.subscription_end_date.seconds * 1000)
      //   d.setDate(d.getDate() + 3 * 7)
      //   this.$firestoreRefs.users.doc(user.id).update({
      //     subscription_end_date: d
      //   })
      // })
    },
    cancelUserEdit() {
      this.selectedUser = {
        id: null,
        balance: 0,
        subscription_end_date: new Date(),
        notes: "",
      };
    },
    deleteUser(user) {
      this.$firestoreRefs.users.doc(user.id).delete();
    },
  },
};
</script>

<style lang="scss" scoped></style>
