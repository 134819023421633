<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div
          class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar"
        >
          <Sidebar />
        </div>
        <div class="column is-full-mobile">
          <div v-if="exam">
            <div
              class="is-flex-desktop is-flex-widescreen is-full-mobile is-justify-content-space-between block"
            >
              <div>
                <h1 class="title mb-1">
                  Exam {{ exam.index }}: {{ exam.title }}
                </h1>
                <nav aria-label="breadcrumbs" class="breadcrumb is-large">
                  <ul>
                    <li>
                      <router-link :to="{ name: 'EducationExams' }">
                        <span>Exams</span>
                      </router-link>
                    </li>
                  </ul>
                </nav>
                <p>{{ exam.description }}</p>
              </div>
              <div class="field is-grouped">
                <p v-if="admin" class="control">
                  <button @click="showResults" class="button is-dark">
                    Results
                  </button>
                </p>
                <p v-if="admin && !edit" class="control">
                  <button @click="editMode" class="button is-dark">Edit</button>
                </p>
                <p v-if="admin && !edit && isExamArchived" class="control">
                  <button
                    @click="changeCategory('active')"
                    class="button is-success"
                    :class="{ 'is-loading': changeCategoryLoading }"
                  >
                    Activate
                  </button>
                </p>
                <p v-if="admin && !edit && isExamActive" class="control">
                  <button
                    @click="changeCategory('archived')"
                    class="button is-danger"
                    :class="{ 'is-loading': changeCategoryLoading }"
                  >
                    Archive
                  </button>
                </p>
                <p v-if="admin && edit" class="control">
                  <button @click="save" class="button is-success">Save</button>
                </p>
                <p v-if="admin && edit" class="control">
                  <button @click="cancelEdit" class="button is-danger">
                    Cancel
                  </button>
                </p>
              </div>
            </div>
            <div class="card block">
              <div class="card-content">
                <form v-if="admin && edit" class="block" @submit.prevent>
                  <div class="field">
                    <label class="label">Title</label>
                    <p class="control">
                      <input
                        v-model.trim="exam.title"
                        class="input"
                        type="text"
                        placeholder="Title"
                      />
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Description</label>
                    <div class="control">
                      <textarea
                        v-model="exam.description"
                        class="textarea"
                        placeholder="Textarea"
                      ></textarea>
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Index</label>
                    <p class="control">
                      <input
                        v-model.number="exam.index"
                        class="input"
                        type="number"
                        placeholder="Index"
                      />
                    </p>
                  </div>
                </form>
                <editor-floating-menu
                  :editor="editor"
                  v-slot="{ commands, isActive, menu }"
                >
                  <div
                    class="editor__floating-menu"
                    :class="{ 'is-active': edit }"
                    :style="`top: ${menu.top}px`"
                  >
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="showImagePrompt(commands.image)"
                    >
                      <i class="fas fa-image"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold"
                    >
                      <i class="fas fa-bold"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic"
                    >
                      <i class="fas fa-italic"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.strike() }"
                      @click="commands.strike"
                    >
                      <i class="fas fa-strikethrough"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.underline() }"
                      @click="commands.underline"
                    >
                      <i class="fas fa-underline"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.code() }"
                      @click="commands.code"
                    >
                      <i class="fas fa-code"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.paragraph() }"
                      @click="commands.paragraph"
                    >
                      <i class="fas fa-paragraph"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                      @click="commands.heading({ level: 1 })"
                    >
                      H1
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                      @click="commands.heading({ level: 2 })"
                    >
                      H2
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                      @click="commands.heading({ level: 3 })"
                    >
                      H3
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 4 }) }"
                      @click="commands.heading({ level: 4 })"
                    >
                      H4
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 5 }) }"
                      @click="commands.heading({ level: 5 })"
                    >
                      H5
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 6 }) }"
                      @click="commands.heading({ level: 6 })"
                    >
                      H6
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.bullet_list() }"
                      @click="commands.bullet_list"
                    >
                      <i class="fas fa-list-ul"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.ordered_list() }"
                      @click="commands.ordered_list"
                    >
                      <i class="fas fa-list-ol"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.blockquote() }"
                      @click="commands.blockquote"
                    >
                      <i class="fas fa-quote-left"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.code_block() }"
                      @click="commands.code_block"
                    >
                      <i class="fas fa-terminal"></i>
                    </button>

                    <button
                      class="menubar__button"
                      @click="commands.horizontal_rule"
                    >
                      ----
                    </button>

                    <button class="menubar__button" @click="commands.undo">
                      <i class="fas fa-undo"></i>
                    </button>

                    <button class="menubar__button" @click="commands.redo">
                      <i class="fas fa-redo"></i>
                    </button>
                  </div>
                </editor-floating-menu>
                <div v-show="showChart" id="live-chart" class="block"></div>
                <editor-content :editor="editor" />
              </div>
            </div>
            <template v-if="admin && edit">
              <div>
                <h2 class="is-large">Questions</h2>
                <button @click="addQuestion()">add choice question</button>
                <button @click="addFreeQuestion()">
                  add free answer question
                </button>
              </div>
              <div
                v-for="question in questions"
                :key="question.id"
                class="card block"
              >
                <div class="card-content">
                  <div class="field">
                    <label class="label">Question</label>
                    <p class="control">
                      <input
                        v-model.trim="question.question"
                        class="input"
                        type="text"
                        placeholder="Add a question"
                      />
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Index</label>
                    <p class="control">
                      <input
                        v-model.number="question.index"
                        class="input"
                        type="number"
                        placeholder="Index"
                      />
                    </p>
                  </div>
                  <template v-if="question.type === 'choice'">
                    <div class="field">
                      <label class="label">Correct Answer</label>
                      <div class="control">
                        <div class="select">
                          <select v-model="question.answer">
                            <option
                              v-for="answer in getQuestionAnswers(question)"
                              :key="answer.id"
                              :value="answer.id"
                              >{{ answer.answer }}</option
                            >
                          </select>
                        </div>
                      </div>
                    </div>
                    <button @click="addAnswer(question)">Add Answer</button>
                    <button @click="updateAnswers(question)">
                      Update Answers
                    </button>
                    <button @click="updateQuestion(question)">
                      Update Question
                    </button>
                    <button @click="deleteQuestion(question)">
                      Delete Question
                    </button>
                    <div
                      v-for="answer in getQuestionAnswers(question)"
                      :key="answer.id"
                    >
                      <div class="field">
                        <label class="label">Answer</label>
                        <p class="control">
                          <input
                            v-model.trim="answer.answer"
                            class="input"
                            type="text"
                            placeholder="Add an answer"
                          />
                        </p>
                      </div>
                      <!--                  <button @click="updateAnswer(answer)">Update</button>-->
                      <button @click="deleteAnswer(answer)">Delete</button>
                    </div>
                  </template>
                  <template v-if="question.type === 'free'">
                    <button @click="updateQuestion(question)">
                      Update Question
                    </button>
                    <button @click="deleteQuestion(question)">
                      Delete Question
                    </button>
                  </template>
                </div>
              </div>
            </template>
            <template v-if="!edit">
              <div class="card block">
                <div class="card-content">
                  <div v-if="resultLoaded && !result" class="field">
                    <label class="label">TradingView Link</label>
                    <div class="control">
                      <input
                        v-model.trim="tradingViewLink"
                        class="input"
                        type="text"
                        placeholder="Paste URL here"
                      />
                    </div>
                  </div>
                  <div v-if="resultLoaded && result" class="field">
                    <label class="label">TradingView Link</label>
                    <div class="control">
                      <input
                        v-model.trim="result.tradingViewLink"
                        disabled
                        class="input"
                        type="text"
                        placeholder="Paste URL here"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-for="question in questions"
                :key="question.id"
                class="card block"
              >
                <div class="card-content">
                  <template v-if="question.type === 'choice'">
                    <div class="field">
                      <label class="label">{{ question.question }}</label>
                      <div
                        v-for="answer in getQuestionAnswers(question)"
                        :key="answer.id"
                      >
                        <label class="radio">
                          <input
                            type="radio"
                            name="question"
                            :value="answer.id"
                            v-model="question.answer"
                          />
                          {{ answer.answer }}
                        </label>
                      </div>
                    </div>
                  </template>
                  <template v-if="question.type === 'free'">
                    <div class="field">
                      <label class="label">{{ question.question }}</label>
                      <textarea
                        v-model.trim="question.answer"
                        :name="question.id"
                        :id="question.id"
                        rows="5"
                        class="is-fullwidth"
                      ></textarea>
                    </div>
                  </template>
                </div>
              </div>
              <div v-if="resultLoaded" class="field is-grouped">
                <div class="control">
                  <!-- <button
                    @click="submitExam"
                    :disabled="result"
                    class="button is-link"
                  >
                    Submit
                  </button> -->
                  <button @click="submitExam" class="button is-link">
                    Submit
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  Editor,
  EditorContent,
  EditorMenuBar,
  EditorFloatingMenu,
} from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
} from "tiptap-extensions";
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import Icon from "@/components/Icon";
import { auth, db } from "@/firebase";

export default {
  name: "EducationExamsExam",
  components: {
    Sidebar,
    EditorContent,
    EditorMenuBar,
    EditorFloatingMenu,
    Icon,
    Mobilemenu,
  },
  // firestore: {
  //   exams: db.collection("exams"),
  // },
  data() {
    return {
      answers: [],
      questions: [],
      result: null,
      resultLoaded: false,
      admin: false,
      tradingViewLink: "",
      edit: false,
      exam: null,
      showChart: true,
      changeCategoryLoading: false,
      editor: new Editor({
        editable: false,
        extensions: [
          new Image(),
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3, 4, 5, 6] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        content: "",
      }),
    };
  },
  watch: {
    id: {
      // call it upon creation too
      immediate: true,
      handler(id) {
        this.$bind(
          "exam",
          db.collection("exams").doc(this.$route.params.exam)
        ).then((exam) => {
          this.editor.setContent(exam.content);
        });
        this.$bind(
          "questions",
          db
            .collection("exams")
            .doc(this.$route.params.exam)
            .collection("questions")
        );
        this.$bind(
          "answers",
          db
            .collection("exams")
            .doc(this.$route.params.exam)
            .collection("answers")
        );
        this.$bind(
          "result",
          db
            .collection("exams")
            .doc(this.$route.params.exam)
            .collection("results")
            .doc(auth.currentUser.uid)
        ).then((result) => {
          this.resultLoaded = true;
        });
      },
    },
  },
  async created() {
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    // Confirm the user is an Admin.
    this.admin = !!idTokenResult.claims.admin;
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  computed: {
    isExamActive() {
      return this.exam.category === "active";
    },
    isExamArchived() {
      return this.exam.category === "archived";
    },
  },
  methods: {
    getQuestionAnswers(question) {
      return this.answers.filter((answer) => answer.question === question.id);
    },
    async addAnswer(question) {
      const answer = {
        question: question.id,
        answer: "",
      };
      await db
        .collection("exams")
        .doc(this.$route.params.exam)
        .collection("answers")
        .add(answer);
    },
    async deleteAnswer(answer) {
      await db
        .collection("exams")
        .doc(this.$route.params.exam)
        .collection("answers")
        .doc(answer.id)
        .delete();
    },
    async addQuestion() {
      const result = await db
        .collection("exams")
        .doc(this.$route.params.exam)
        .collection("questions")
        .add({
          question: "new question",
          type: "choice",
          index: this.questions.length,
        });
      console.log(result);
    },
    async addFreeQuestion() {
      const result = await db
        .collection("exams")
        .doc(this.$route.params.exam)
        .collection("questions")
        .add({
          question: "new question",
          type: "free",
          index: this.questions.length,
        });
      console.log(result);
    },
    async updateQuestion(question) {
      await db
        .collection("exams")
        .doc(this.$route.params.exam)
        .collection("questions")
        .doc(question.id)
        .update({
          question: question.question,
          index: question.index,
          answer: question.answer,
        });
    },
    async deleteQuestion(question) {
      this.deleteAnswers(question);
      await db
        .collection("exams")
        .doc(this.$route.params.exam)
        .collection("questions")
        .doc(question.id)
        .delete();
    },
    deleteAnswers(question) {
      this.getQuestionAnswers(question).forEach(async (answer) => {
        await db
          .collection("exams")
          .doc(this.$route.params.exam)
          .collection("answers")
          .doc(answer.id)
          .delete();
      });
    },
    updateAnswers(question) {
      this.getQuestionAnswers(question).forEach(async (answer) => {
        await db
          .collection("exams")
          .doc(this.$route.params.exam)
          .collection("answers")
          .doc(answer.id)
          .update({
            answer: answer.answer,
          });
      });
    },
    showImagePrompt(command) {
      const src = prompt("Paste the URL of your image here");
      if (src !== null) {
        command({ src });
      }
    },
    async submitExam() {
      let data = {
        tradingViewLink: this.tradingViewLink,
      };
      this.questions.forEach((question) => {
        data[`${question.id}`] = question.answer;
      });
      const result = await db
        .collection("exams")
        .doc(this.$route.params.exam)
        .collection("results")
        .doc(auth.currentUser.uid)
        .set(data);
      console.log(result);
    },
    async save() {
      const content = this.editor.getHTML();
      await this.$firestoreRefs.exam.update({
        content: content,
        title: this.exam.title,
        description: this.exam.description,
        index: this.exam.index,
      });
      this.edit = false;
      this.editor.setOptions({
        editable: false,
      });
    },
    async changeCategory(category) {
      try {
        this.changeCategoryLoading = true;
        await this.$firestoreRefs.exam.update({
          category: category,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.changeCategoryLoading = false;
      }
    },
    cancelEdit() {
      this.edit = false;
      this.editor.setOptions({
        editable: false,
      });
    },
    editMode() {
      this.edit = true;
      this.editor.setOptions({
        editable: true,
      });
    },
    async showResults() {
      await this.$router.push({
        name: "EducationExamsExamResults",
        params: { exam: this.$route.params.exam },
      });
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/variables";

.ProseMirror {
  &:focus {
    outline: none;
  }

  ul {
    list-style-type: disc;
    padding-left: 36px;
  }

  h2 {
    color: var(--text-tertiary-color);
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h2 {
    color: var(--text-tertiary-color);
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h3 {
    color: var(--text-tertiary-color);
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h4 {
    color: var(--text-tertiary-color);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h5 {
    color: var(--text-tertiary-color);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h6 {
    color: var(--text-tertiary-color);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.125;
  }

  pre {
    padding: 0.7rem 1rem;
    border-radius: 5px;
    background: $color-black;
    color: $color-white;
    font-size: 0.8rem;
    overflow-x: auto;

    code {
      display: block;
    }
  }

  p code {
    padding: 0.2rem 0.4rem;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    background: rgba($color-black, 0.1);
    color: rgba($color-black, 0.8);
  }

  ul,
  ol {
    padding-left: 2rem;
  }

  li > p,
  li > ol,
  li > ul {
    margin: 0;
  }

  a {
    color: inherit;
  }

  blockquote {
    border-left: 3px solid rgba($color-black, 0.1);
    color: rgba($color-black, 0.8);
    padding-left: 0.8rem;
    font-style: italic;

    p {
      margin: 0;
    }
  }

  img {
    max-width: 100%;
    border-radius: 3px;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid $color-grey;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: 0;
      width: 4px;
      z-index: 20;
      background-color: #adf;
      pointer-events: none;
    }
  }

  .tableWrapper {
    margin: 1em 0;
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

  blockquote,
  h1,
  h2,
  h3,
  ol,
  p,
  pre,
  ul {
    margin: 1rem 0;
  }
}

.editor {
  position: relative;

  &__floating-menu {
    position: absolute;
    z-index: 1;
    margin-top: -0.25rem;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;

    &.is-active {
      opacity: 1;
      visibility: visible;
      background: lavenderblush;
      position: fixed;
      top: 0;
      padding: 10px;
    }
  }
}
</style>
